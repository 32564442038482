import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AppWrapper } from "./AppWrapper";
import { Provider } from "react-redux";
import { store } from "./redux/store.js";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <Provider store={store}>
            <AppWrapper>
                <App />
            </AppWrapper>
        </Provider>
    </React.StrictMode>
);
