import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAnglesRight,
  faBell,
  faCircle,
  faFilter,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useGetPatainetInfoQuery } from "../../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { setSidePanel } from "../../../redux/reducers/sidePanel";
library.add(faXmark, faAnglesRight, faBell, faAnglesRight, faFilter, faCircle);

function ModalHeader({
  notificationCounter,
  showMedrec,
  patientMode,
  setPayload,
  payload,
  botStatus,
  handleNarrativeGuideNextStep,
}) {
  const sidePanel = useSelector((state) => state.sidePanel.value);

  const [filter, setFilter] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState("all");

  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(setSidePanel(false));
  };
  const showStatus = () => {
    if (!showMedrec && !patientMode) {
      return "Messaging AI Assistant";
    } else if (showMedrec && !patientMode) {
      return "Monitoring AI agent";
    } else if (patientMode) {
      return "Messaging Patient";
    }
  };
  const styles = {
    Text: {
      color: "#a8a8a8",
      fontSize: "14px",
      fontFamily: "Roboto",
      lineHeight: "16px",
      marginTop: "-8px",
    },
    title: {
      color: "#e6d9e8",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "500",
    },
  };

  const handleFilter = (e) => {
    const value = e.target.value;
    localStorage.setItem("frqfilter", value);
    setFilterValue(value);
    setPayload({ ...payload, filter: value });
    handleNarrativeGuideNextStep();
  };

  useEffect(() => {
    if (localStorage.getItem("frqfilter")) {
      setFilterValue(localStorage.getItem("frqfilter"));
    }
  }, []);

  const colors = {
    stop: "rgb(231, 233, 237)",
    pause: "#ffeeaa",
    resume: "#c3f2cb",
  };

  return (
    <div className="quick-message-modal-header">
     

      <div className="idv">
      
        {/* <div className="notification quick-message-notification-icon">
          <FontAwesomeIcon icon="fa-solid fa-bell" />
          <span className="notification-counter">{notificationCounter}</span>
        </div> */}
      </div>
    </div>
  );
}

export default ModalHeader;