import { configureStore } from '@reduxjs/toolkit'
import { appAPI } from '../api/api'
import { setupListeners } from '@reduxjs/toolkit/query'
import sidePanelReducer from './reducers/sidePanel'
export const store = configureStore({
  reducer: {
    [appAPI.reducerPath]: appAPI.reducer,
    sidePanel: sidePanelReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      appAPI.middleware,
    ),
})
setupListeners(store.dispatch)
