// src/AppWrapper.js
import React, { useEffect, useState } from "react";
import { VimOSContext } from "./VimOSContext";
import QuickMessageModal from "./components/QuickMessageModal";
import "./App.css";
import HomePage from "./pages/HomePage";
export const AppWrapper = ({ children }) => {
    const [vimOS, setVimOS] = useState(undefined);

    useEffect(() => {
        const initializeVimSDK = async () => {
            try {
                const sdk = await window.vimSdk.initializeVimSDK({
                    appTokenEndpoint:
                        "https://vim-backend.frqhealth.com/api/generate-token", // your token generation endpoint
                });
                setVimOS(sdk);
                sdk.hub.setActivationStatus("ENABLED");
                sdk.hub.setTooltipText("frq pharmacy");
            } catch (error) {
                console.error("Failed to initialize Vim SDK", error);
            }
        };

        initializeVimSDK();
    }, []);

    if (!vimOS) {
        return (
            <div>
                Loading VimSDK... <HomePage />
            </div>
        );
    }

    return (
        <VimOSContext.Provider value={vimOS}>{children}</VimOSContext.Provider>
    );
};
