import React from 'react';

const ItemCard = ({ item }) => {

    return (
        <div className="relative flex flex-col my-4 bg-white">
            <div className="flex items-center mb-1">
                <h5 className="ml-1 text-slate-800 text-xl font-semibold">
                    {item.description}
                </h5>
            </div>
        </div>

    );
};

export default ItemCard;
