import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const siteURL = import.meta.env.VITE_REACT_APP_API_URL
const siteURL = "https://controls-uat.frqtechnologies.com";

// const debug1 = process.env.VITE_REACT_APP_API_URL
// console.log('api1: ' + debug1)

// const debug2 = process.env.VITE_REACT_APP_PHARMACIST_ID
// console.log('api2: ' + debug2)
// console.log('api3: ' + JSON.stringify(process.env))

export const appAPI = createApi({
    reducerPath: "appAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: siteURL,
    }),

    tagTypes: [
        "patientCard",
        "chronologicalSummaryRefresh",
        "medicationRefresh",
        "allergiesRefresh",
        "conditionRefresh",
    ],
    keepUnusedDataFor: 0.1,
    endpoints: (builder) => ({
        sendMessages: builder.mutation({
            query: (payload) => ({
                url: "/api/message/",
                method: "POST",
                body: payload,
            }),
        }),
        getPatientBotSession: builder.mutation({
            query: (payload) => ({
                url: `/api/patient-bot/`,
                method: "POST",
                body: payload,
            }),
        }),
        getMedicationRefresh: builder.query({
            query: (payload) => ({
                url: `/api/medication2/refresh2/?${new URLSearchParams(
                    payload
                )}`,
                method: "GET",
            }),
        }),
        postMedicationRefresh: builder.mutation({
            query: (payload) => ({
                url: `/api/medication2/refresh2/`,
                method: "POST",
                body: payload,
            }),
        }),
        registerUser: builder.mutation({
            query: (payload) => ({
                url: "/auth/register/",
                method: "POST",
                body: payload,
            }),
        }),
        loginUser: builder.mutation({
            query: (payload) => ({
                url: "/auth/login/",
                method: "POST",
                body: payload,
            }),
        }),
        logoutUser: builder.query({
            query: () => ({
                url: "/api/logout/",
                method: "GET",
            }),
        }),
        getMessages: builder.query({
            query: (payload) => ({
                url: `/api/message?${new URLSearchParams(payload)}`,
                method: "GET",
            }),
        }),
        deleteBotMemory: builder.mutation({
            query: (payload) => ({
                url: `/api/clear-medrec-bot-memory/?${new URLSearchParams(
                    payload
                )}`,
                method: "DELETE",
            }),
        }),
        getChoronologicalSummary: builder.query({
            query: (payload) => ({
                url: `/api/patient/chorno/?${new URLSearchParams(payload)}`,
                method: "GET",
            }),
            providesTags: ["chronologicalSummaryRefresh"],
        }),
        refreshChoronologicalSummary: builder.mutation({
            query: (payload) => ({
                url: `/api/patient/chorno/refresh/`,
                method: "POST",
                body: payload,
            }),
            providesTags: ["chronologicalSummaryRefresh"],
        }),
        clinicalNoteAgent: builder.mutation({
            query: (payload) => ({
                url: `/api/patient/clinical-note-recommendation-agent`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["chronologicalSummaryRefresh"],
        }),
        updateMedrecBotStatus: builder.mutation({
            query: (payload) => ({
                url: "/api/update-medrec-bot-session-state/",
                method: "PUT",
                body: payload,
            }),
            providesTags: ["chronologicalSummaryRefresh"],
        }),
        phoneLoginUser: builder.mutation({
            query: (payload) => ({
                url: "/auth/login/phone/",
                method: "POST",
                body: payload,
            }),
        }),
        otpVerify: builder.mutation({
            query: (payload) => ({
                url: "/auth/login/phone/otp-verify/",
                method: "POST",
                body: payload,
            }),
        }),
        getAllPatientList: builder.query({
            query: (payload) => ({
                url: `/api/patient/paitentcard/?${new URLSearchParams(
                    payload
                )}`,
                method: "GET",
            }),
            providesTags: ["patientList"],
        }),
        getPatientCard: builder.query({
            query: (payload) => ({
                url: `/summary/paitentcard/?${new URLSearchParams(payload)}`,
                method: "GET",
            }),
            providesTags: ["patientCard"],
        }),
        getRouterChat: builder.query({
            query: (payload) => ({
                url: `/bot/ai-agent/messages/?${new URLSearchParams(payload)}`,
                method: "GET",
            }),
            providesTags: ["patientCard"],
        }),
        patientCardRefresh: builder.mutation({
            query: (payload) => ({
                url: "/summary/patientcard/refresh/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["patientCard"],
        }),
        getChronologicalSummary: builder.query({
            query: (payload) => ({
                url: `/summary/chorno/?${new URLSearchParams(payload)}`,
                method: "GET",
            }),
            providesTags: ["chronologicalSummaryRefresh"],
        }),
        chronologicalSummaryRefresh: builder.mutation({
            query: (payload) => ({
                url: "/summary/chorno/refresh/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["chronologicalSummaryRefresh"],
        }),
        getMedication: builder.query({
            query: (payload) => ({
                url: `/summary/medication/?${new URLSearchParams(payload)}`,
                method: "GET",
            }),
            providesTags: ["medicationRefresh"],
        }),
        addMedication: builder.mutation({
            query: (payload) => ({
                url: "/summary/medication/current/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["medicationRefresh"],
        }),
        updateMedication: builder.mutation({
            query: (payload) => ({
                url: "/summary/medication/current/",
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["medicationRefresh"],
        }),
        deleteMedication: builder.mutation({
            query: (payload) => ({
                url: "/summary/medication/current/",
                method: "DELETE",
                body: payload,
            }),
            invalidatesTags: ["medicationRefresh"],
        }),
        addMedicationHistory: builder.mutation({
            query: (payload) => ({
                url: "/summary/medication/history/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["medicationRefresh"],
        }),
        updateMedicationHistory: builder.mutation({
            query: (payload) => ({
                url: "/summary/medication/history/",
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["medicationRefresh"],
        }),
        deleteMedicationHistory: builder.mutation({
            query: (payload) => ({
                url: "/summary/medication/history/",
                method: "DELETE",
                body: payload,
            }),
            invalidatesTags: ["medicationRefresh"],
        }),
        medicationRefresh: builder.mutation({
            query: (payload) => ({
                url: "/summary/medication/refresh/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["medicationRefresh"],
        }),
        conditionAllergyRefresh: builder.mutation({
            query: (payload) => ({
                url: "/summary/condition-allergies/refresh/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["allergiesRefresh", "conditionRefresh"],
        }),
        getPatainetInfo: builder.query({
            query: (payload) => ({
                url: `/summary/me/?${new URLSearchParams(payload)}`,
                method: "GET",
            }),
        }),
        getNarrativeGuideNext: builder.query({
            query: (payload) => ({
                url: `/narrative/guide-next?${new URLSearchParams(payload)}`,
                method: "GET",
            }),
        }),
        getNarrativeInit: builder.query({
            query: (payload) => ({
                url: `/narrative/init?${new URLSearchParams(payload)}`,
                method: "GET",
            }),
        }),
        getNarrativeClear: builder.query({
            query: (payload) => ({
                url: `/narrative/clear?${new URLSearchParams(payload)}`,
                method: "GET",
            }),
        }),
        patientCardActionStatusUpdate: builder.mutation({
            query: (payload) => ({
                url: "/summary/patientcard/action/update/",
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["patientCard"],
        }),
        getConditions: builder.query({
            query: (payload) => ({
                url: `/summary/condition-allergies/?${new URLSearchParams(
                    payload
                )}`,
                method: "GET",
            }),
            providesTags: ["allergiesRefresh", "conditionRefresh"],
        }),
        addAllergies: builder.mutation({
            query: (payload) => ({
                url: "/summary/allergy/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["allergiesRefresh"],
        }),
        updateAllergies: builder.mutation({
            query: (payload) => ({
                url: "/summary/allergy/",
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["allergiesRefresh"],
        }),
        deleteAllergies: builder.mutation({
            query: (payload) => ({
                url: "/summary/allergy/",
                method: "DELETE",
                body: payload,
            }),
            invalidatesTags: ["allergiesRefresh"],
        }),
        addCondition: builder.mutation({
            query: (payload) => ({
                url: "/summary/condition/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["conditionRefresh"],
        }),
        updateCondition: builder.mutation({
            query: (payload) => ({
                url: "/summary/condition/",
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["conditionRefresh"],
        }),
        deleteCondition: builder.mutation({
            query: (payload) => ({
                url: "/summary/condition/",
                method: "DELETE",
                body: payload,
            }),
            invalidatesTags: ["conditionRefresh"],
        }),
        feedbackOnMessage: builder.mutation({
            query: (payload) => ({
                url: "/feedback/feedback/",
                method: "POST",
                body: payload,
            }),
        }),
        controlMedrec: builder.mutation({
            query: (payload) => ({
                url: "/control/medrec/",
                method: "POST",
                body: payload,
            }),
        }),
        botStream: builder.mutation({
            query: (payload) => ({
                url: "/bot/ai-agent/stream/",
                method: "POST",
                body: payload,
            }),
        }),
        patientChat: builder.mutation({
            query: (payload) => ({
                url: "/bot/patient/",
                method: "POST",
                body: payload,
            }),
        }),
    }),
});

export const {
    useGetPatientCardQuery,
    useGetAllPatientListQuery,
    useGetChronologicalSummaryQuery,
    useGetMedicationQuery,
    useRegisterUserMutation,
    useLoginUserMutation,
    useGetPatainetInfoQuery,
    useGetNarrativeInitQuery,
    useGetNarrativeClearQuery,
    useGetNarrativeGuideNextQuery,
    usePatientCardActionStatusUpdateMutation,
    useChronologicalSummaryRefreshMutation,
    useMedicationRefreshMutation,
    usePatientCardRefreshMutation,
    useGetConditionsQuery,
    useAddAllergiesMutation,
    useAddConditionMutation,
    useAddMedicationMutation,
    useAddMedicationHistoryMutation,
    useDeleteAllergiesMutation,
    useUpdateAllergiesMutation,
    useDeleteConditionMutation,
    useDeleteMedicationMutation,
    useDeleteMedicationHistoryMutation,
    useUpdateConditionMutation,
    useUpdateMedicationMutation,
    useUpdateMedicationHistoryMutation,
    useConditionAllergyRefreshMutation,
    useFeedbackOnMessageMutation,
    usePhoneLoginUserMutation,
    useOtpVerifyMutation,
    useControlMedrecMutation,
    useGetRouterChatQuery,
    useBotStreamMutation,
    usePatientChatMutation,
    useLazyLogoutUserQuery,
    useGetMessagesQuery,
    useSendMessagesMutation,
    useGetPatientBotSessionMutation,
    useGetMedicationRefreshQuery,
    usePostMedicationRefreshMutation,
    useDeleteBotMemoryMutation,
    useGetChoronologicalSummaryQuery,
    useRefreshChoronologicalSummaryMutation,
    useUpdateMedrecBotStatusMutation,
    useClinicalNoteAgentMutation,
} = appAPI;
