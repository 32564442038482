import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPause,
  faPlay,
  faSpinner,
  faStop,
} from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useRef, useState } from 'react'
import { useFeedbackOnMessageMutation } from '../../../api/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import aiAssistantLogo from '../../../assets/img/ai-assistant_13481632.png'
import systemSuggestion from '../../../assets/img/SystemSuggestionIcon.png'
import notificationLogo from '../../../assets/img/notificationLogo.png'
import doctorLogo from '../../../assets/img/doctor_8997187.png'
import userLogo from '../../../assets/img/user_1077012.png'
import TypingAnimation from '../../TypingAnimation'
library.add(faSpinner, faStop, faPlay, faPause)
function ModalBody({
  messageHistory,
  routerLoading,
  medRecMessageHistory,
  showMedrec,
  patientMode,
  botStatus,
  setBotStatus,
  showBeacon,
}) {
  const [commentValue, setCommentvalue] = useState('')
  const [commentBox, setCommentBox] = useState({})
  const [showMore, setShowMore] = useState({})

  const toggleShowMore = (id) => {
    setShowMore((prevState) => ({ ...prevState, [id]: !prevState[id] }))
  }

  const messageContainerRef = useRef(null)
  const medrecMessageContainerRef = useRef(null)
  const commentRef = useRef(null)

  const pharmacistId = localStorage.getItem('frqLogin')
  const patientId = localStorage.getItem('frqPatientId')

  const [feedbackOnMessage, { isLoading, data }] =
    useFeedbackOnMessageMutation()

  let timeoutId = useRef(null)

  const scrollToBottom = () => {
    const container = messageContainerRef.current
    if (container) {
      container.scrollTop = container.scrollHeight - container.clientHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messageHistory,medRecMessageHistory])

  const formatTime = (date) => {
    typeof date == 'undefined' && (date = new Date())
    const dateObject = new Date(date)
    const timeString = dateObject.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })

    return timeString
  }

  const handleCommentOpen = (messageId) => {
    setCommentBox((prevState) => ({
      ...prevState,
      [messageId]: !prevState[messageId],
    }))
  }

  const handleFeedback = (e, feedbackMsg, thumb = '') => {
    e.currentTarget.classList.add('active')
    try {
      feedbackOnMessage({
        pharmacist_user_id: pharmacistId,
        patient_id: patientId,
        original_message: feedbackMsg,
        feedback: 'string',
        thumb: thumb,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleFeedbackWithComment = (e, feedbackMsg) => {
    e.preventDefault()
    const commentValue = commentRef.current.value
    try {
      feedbackOnMessage({
        pharmacist_user_id: pharmacistId,
        patient_id: patientId,
        original_message: feedbackMsg,
        feedback: commentValue,
        thumb: '',
      })
      commentRef.current.value = ''
      setCommentvalue('')
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (data) {
      setCommentBox({})
      setCommentvalue('')
    }
  }, [data])

  

  const getMessageBackground = (sender) => {
    const normalizedSender = sender.trim().toLowerCase()
    if (normalizedSender == 'patient') {
      return 'rgba(186, 104, 200, 0.05)'
    }
  }

  const addTargetBlank = (htmlString) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const links = doc.querySelectorAll('a')

    links.forEach((link) => {
      link.setAttribute('target', '_blank')
    })

    return doc.body.innerHTML
  }

  function collapseText(text, id) {
    // toggleShowMore(id)
    const words = text.split(' ')
    if (words.length <= 50) {
      return text
    } else {
      return words.slice(0, 35).join(' ') + '...'
    }
  }

  return (
    <div
      className={`quick-message-modal-body`}
      ref={messageContainerRef}
      style={{ height: 'calc(100% - 120px)' }}
    >
      <>
        {messageHistory && messageHistory.map((msg, index) => {
          const messageContent = showMore[msg.message_id]
            ? msg.content
            : collapseText(msg.content, msg.message_id)
          const words = msg.content.split(' ')
          return (
            <div
              className="messages"
              key={index}
              style={{ backgroundColor: getMessageBackground(msg.sender) }}
            >
              <div className="left">
                <div className="avatar">
                  <SenderImage sender={msg.sender} />
                </div>
              </div>

              <div className="right">
                <div className="message-content">
                  <div className="msg-sender-wrapper">
                    <h2>{msg.sender}</h2>
                    <p>{formatTime(msg.message_created)}</p>
                  </div>
                  <div>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: addTargetBlank(messageContent),
                      }}
                    ></div>
                    {/* {msg.summary_ids?.length > 0 && (
                        <button>{msg.summary_ids[0]}</button>
                      )} */}
                    {words.length >= 50 && (
                      <button
                        onClick={() => toggleShowMore(msg.message_id)}
                        className="see-more"
                      >
                        {showMore[msg.message_id] ? 'Show Less' : 'See More'}
                      </button>
                    )}
                  </div>
                </div>
                <div className="message-feedback">
                  <button onClick={(e) => handleFeedback(e, msg.message, 'up')}>
                    <FontAwesomeIcon icon="fa-solid fa-thumbs-up" />
                  </button>
                  <button
                    onClick={(e) => handleFeedback(e, msg.message, 'down')}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-thumbs-down" />
                  </button>
                  <button onClick={() => handleCommentOpen(msg.message_id)}>
                    <FontAwesomeIcon icon="fa-solid fa-comment" />
                  </button>
                  {commentBox[msg.message_id] && (
                    <form
                      className="comment-box"
                      onSubmit={(e) =>
                        handleFeedbackWithComment(e, msg.message)
                      }
                    >
                      <input
                        type="text"
                        name="feedbackComment"
                        value={commentValue}
                        ref={commentRef}
                        onChange={(e) => setCommentvalue(e.target.value)}
                      />
                      <button type="submit">
                        Comment
                        {isLoading && (
                          <span>
                            <FontAwesomeIcon
                              icon="fa-solid fa-spinner"
                              style={{ marginLeft: '10px' }}
                              className="spinner"
                            />
                          </span>
                        )}
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </>

      {routerLoading && !patientMode && (
        <div className="loading">
          <TypingAnimation />
        </div>
      )}
    </div>
  )
}

export default ModalBody

const SenderImage = ({ sender }) => {
  const normalizedSender = sender.trim().toLowerCase()
  switch (normalizedSender) {
    case 'pharmacist':
      return <img src={doctorLogo} alt="logo" />
    case 'patient':
      return <img src={doctorLogo} alt="logo" />
    case 'ai agent: medication reconciliation':
      return <img src={aiAssistantLogo} alt="logo" />
    case 'router-bot':
      return <img src={aiAssistantLogo} alt="logo" />
    case 'medrec-bot':
      return <img src={aiAssistantLogo} alt="logo" />
    case 'system suggestion':
      return <img src={systemSuggestion} alt="logo" />
    case 'notification':
      return (
        <img className="notificationLogo" src={notificationLogo} alt="logo" />
      )
    default:
      return <img src={userLogo} alt="logo" />
  }
}
