import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faCircle)

const TypingAnimation = () => {
  return (
    <div className="typing">
      <FontAwesomeIcon icon="fa-solid fa-circle" className="dot" />
      <FontAwesomeIcon icon="fa-solid fa-circle" className="dot" />
      <FontAwesomeIcon icon="fa-solid fa-circle" className="dot" />
    </div>
  )
}

export default TypingAnimation
