import React, { useState } from 'react';
import { debounce } from '../utils/debounce';
import { searchItems } from '../services/api';

const searchItemsList = [
    { code: 1, description: 'Medrek Ai Agents' },
    { code: 2, description: 'Repel Ai Agents' },

]

const SearchBar = ({ setSearchItem }) => {
    const [query, setQuery] = useState('');

    const handleSearch = debounce(async (query) => {
        if (query) {
            // const data = await searchItems(query);
            const data = searchItemsList.filter(item => item.description.toLowerCase().includes(query.toLowerCase()));
            setSearchItem(data);
        }

    }, 300);

    const handleChange = (e) => {
        const value = e.target.value;
        setQuery(value);
        handleSearch(value);
    };

    return (
        <input
            type="text"
            placeholder="Describe the task"
            value={query}
            onChange={handleChange}
            className="p-2 border rounded w-full"
        />
    );
};

export default SearchBar;
