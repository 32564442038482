import { useContext, useState, useEffect, useCallback } from "react";
import { VimOSContext } from "./VimOSContext";

export const useVimOSPatient = () => {
  const vimOS = useContext(VimOSContext);
  const [patient, setPatient] = useState(undefined);
  const [problemList, setProblemList] = useState([]);

  // Memoized callback to fetch the problem list
  const getProblemList = useCallback(async () => {
    if (vimOS?.ehr?.ehrState?.patient) {
      try {
        const problemList = await vimOS.ehr.ehrState.patient.getProblemList();
        return problemList;
      } catch (error) {
        console.error("Failed to load problem list", error);
        return []; // Fallback to empty array if error occurs
      }
    }
    return []; // Return empty array if patient data is unavailable
  }, [vimOS?.ehr?.ehrState]);

  // Function to send patient info to backend
  const sendPatientInfoToBackend = async (patientData) => {
    try {
      const response = await fetch(
        "https://vim-backend.frqhealth.com/patients",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(patientData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Successfully sent patient info to backend:", result);

      // Optionally, store the patientId in localStorage
      localStorage.setItem(
        "frqPatientId",
        patientData?.identifiers?.vimPatientId
      );
    } catch (error) {
      console.error("Failed to send patient info to backend", error);
    }
  };

  useEffect(() => {
    // Ensure vimOS is available before proceeding
    if (!vimOS?.ehr) return;

    const cb = async (newPatient) => {
      // Get problem list and send to backend
      const newProblemList = await getProblemList();
      await sendPatientInfoToBackend({
        ...newPatient,
        problemList: newProblemList,
      });

      // Update patient and problem list state
      setPatient(newPatient);
      setProblemList(newProblemList);
    };

    // Subscribe to the "patient" event
    vimOS.ehr.subscribe("patient", cb);

    // Check if the patient exists at the time of initial render
    if (vimOS?.ehr?.patient?.identifiers?.vimPatientId) {
      (async () => {
        const initialProblemList = await getProblemList();
        setProblemList(initialProblemList);
        setPatient((prevPatient) => ({
          ...prevPatient,
          problemList: initialProblemList,
        }));

        if (vimOS.ehr?.patient?.identifiers?.vimPatientId && initialProblemList) {
          await sendPatientInfoToBackend({
            ...vimOS.ehr.patient,
            problemList: initialProblemList,
          });
        }
      })();
    }

    // Cleanup the subscription when component unmounts
    return () => {
      vimOS.ehr.unsubscribe("patient", cb);
    };
  }, [vimOS, getProblemList]); // Dependencies are vimOS and getProblemList

  return patient; // Return the patient object
};
