import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faA,
  faFaceSmile,
  faLink,
  faMicrophone,
  faPaperPlane,
  faPaperclip,
  faVideo,
  faXmark,
  faNotesMedical,
  faThumbsUp,
  faThumbsDown,
  faComment,
} from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from '@mui/material'
import {
  useChronologicalSummaryRefreshMutation,
  useConditionAllergyRefreshMutation,
  useControlMedrecMutation,
  useDeleteBotMemoryMutation,
  useGetPatientBotSessionMutation,
  useMedicationRefreshMutation,
  useRefreshChoronologicalSummaryMutation,
  useUpdateMedrecBotStatusMutation,
} from '../../../api/api'
import { Field, Form, Formik } from 'formik'
import grainIcon from '../../../assets/img/icon-grain.png'
import expandIcon from '../../../assets/img/expand-more.png'
import frqLogo from '../../../assets/img/logo.png'

library.add(
  faPaperPlane,
  faA,
  faPaperclip,
  faLink,
  faMicrophone,
  faVideo,
  faFaceSmile,
  faXmark,
  faNotesMedical,
  faThumbsUp,
  faThumbsDown,
  faComment,
)

function ModalFooter({
  sendMessageBtn,
  patientMode,
  setPatientMode,
  setNotificationCounter,
  botStatus,
  routerLoading,
  handleNarrativeGuideNextStep,
  stopNarrative,
  setShowSchedulerModal,
  setBotStatus,
}) {
  const [updateColor, setUpdateColor] = useState({})

  const [refreshChoronologicalSummary] =
    useRefreshChoronologicalSummaryMutation()
  const [medicationRefresh, { isLoading }] = useMedicationRefreshMutation()
  const [conditionAllergyRefresh] = useConditionAllergyRefreshMutation()
  const [deleteBotMemory] = useDeleteBotMemoryMutation()
  const [
    controlMedrec,
    { isLoading: medrecControlIsloading, data: medrecControlData },
  ] = useControlMedrecMutation()

  const formRef = useRef(null)
  const inputRef = useRef(null)
  const pharmacist_id = '560fb893-d6fd-4f28-9c34-12c5d1f8072c'
  const pharmacist_user_id = '55445413-4556-4d10-b735-8b91a8a6812b'
  const patient_id = 'a2623098-97a8-4438-9147-8b636844f72a'
  

  const [updateMedrecBotStatus, { data: botStatusData }] =
    useUpdateMedrecBotStatusMutation()

  useEffect(() => {
    if (botStatusData) {
      setBotStatus(botStatusData.updated_value)
    }
  }, [botStatusData])


  function hasPatientMention(text) {
    const regex = /@Patient/i
    return regex.test(text)
  }

  const handleKeyPress = (event) => {
    const messageCharacter = event.target.value

    if (hasPatientMention(messageCharacter)) {
      setPatientMode(true)
    } else {
      setPatientMode(false)
    }

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        formRef.current.submitForm()
      } else if (event.ctrlKey && event.shiftKey && event.key === 'Enter') {
        setPatientMode(true)
        formRef.current.submitForm()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [formRef])

  const handleFocus = () => {
    setNotificationCounter(0)
  }

  const handleBlur = () => {
    setNotificationCounter(0)
  }

  const stopMedrec = () => {
    try {
      updateMedrecBotStatus({
        patient_id: patient_id,
        pharmacist_id: pharmacist_id,
        session_state: 'STOPPED',
      })
    } catch (e) {
      console.log(e)
    }
  }
  const resumeMedrec = () => {
    try {
      updateMedrecBotStatus({
        patient_id: patient_id,
        pharmacist_id: pharmacist_id,
        session_state: 'ACTIVE',
      })
    } catch (e) {
      console.log(e)
    }
  }
  const pauseMedrec = () => {
    try {
      updateMedrecBotStatus({
        patient_id: patient_id,
        pharmacist_id: pharmacist_id,
        session_state: 'INACTIVE',
      })
    } catch (e) {
      console.log(e)
    }
  }

  const deleteMedrec = () => {
    try {
      deleteBotMemory({
        pharmacist_id: pharmacist_id,
        patient_id: patient_id,
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div
      className="quick-message-modal-footer"
      style={{ position: 'relative' }}
    >
      <Formik
        initialValues={{
          message: '',
        }}
        onSubmit={sendMessageBtn}
        innerRef={(f) => (formRef.current = f)}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="quick-message-input" style={updateColor}>
              <Field
                name="message"
                as="textarea"
                className="message-input-field chat-input"
                onFocus={handleFocus}
                onBlur={handleBlur}
                innerRef={(f) => (inputRef.current = f)}
                onKeyPress={handleKeyPress}
                placeholder="Message ..."
              />
            
              <div className="toolbar">
                <div className="toolbar-left">
                </div>
                <div className="send-button">
                  <button type="submit" disabled={isSubmitting} className="">
                    Send
                  </button>
                  <div
                    style={{
                      height: '12px',
                      width: '1px',
                      background: 'white',
                    }}
                  ></div>
                  <img
                    onClick={() => setShowSchedulerModal(true)}
                    className="arrow"
                    src={expandIcon}
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ModalFooter

const styles = {
  poweredBy: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '3px',
  },

  AiButton: {
    width: '22px',
    height: '22px',
    border: '0',
    boxSizing: 'border-box',
    borderRadius: '50%',
    color: 'white',
    fontSize: '12px',
    fontFamily: 'Nunito',
    outline: 'none',
    padding: '0',
    marginLeft: '5px',
    backgroundColor: '#a8a8a8',
  },
  AiPink: {
    width: '22px',
    height: '22px',
    border: '0',
    boxSizing: 'border-box',
    borderRadius: '50%',
    color: 'white',
    fontSize: '12px',
    fontFamily: 'Nunito',
    outline: 'none',
    padding: '0',
    marginLeft: '5px',
    backgroundColor: '#cd93d7',
  },
  AiYellow: {
    width: '22px',
    height: '22px',
    border: '0',
    boxSizing: 'border-box',
    borderRadius: '50%',
    backgroundColor: '#ffeeaa',
    color: 'gray',
    fontSize: '12px',
    fontFamily: 'Nunito',
    outline: 'none',
    padding: '0',
    marginLeft: '5px',
  },
}
