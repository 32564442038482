
import React, { useContext, useEffect, useState } from "react";
import { VimOSContext } from "./VimOSContext";
import { PatientName } from "./PatientName";
import HomePage from "./pages/HomePage";
import { useVimOSPatient } from "./useVimOSPatient";

const App = () => {
  const vimOS = useContext(VimOSContext);
  const patients = useVimOSPatient();

const patientId = localStorage.getItem("frqPatientId");
  const [isLoaded, setIsLoaded] = useState(false);
  const [patient,setPatient] = useState([])
  useEffect(() => {
    if (vimOS) {
      const checkInitialization = async () => {
        // Simulating an async check (e.g., fetching data, initializing SDK)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsLoaded(true);
      };

      checkInitialization();
setPatient(vimOS?.ehr?.ehrState.patient)
    }
  }, [vimOS,vimOS?.ehr?.ehrState?.patient,patient]);

  if (!vimOS || !isLoaded) {
    return <div>Loading Vim SDK...</div>;
  }

 

  return (
    <div>
       <PatientName /> 
      {
        patients
          ? <HomePage />
          : <h1 className="text-center">Please select a patient</h1>
      }
    </div>
  );
};

export default App;
