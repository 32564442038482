import React, { useEffect, useState, useCallback, useContext } from 'react';
import ItemCard from '../components/ItemCard';
import SearchBar from '../components/SearchBar';
import Loader from '../components/Loader';
import { useVimOSPatient } from "../useVimOSPatient";

// Initial data for items, can be replaced with actual API fetch later
const initialItems = [
    { code: 'I20.9', description: 'Angina pectoris, unspecified' },
    { code: 'K21.0', description: 'Gastro-esophageal reflux disease without esophagitis' },
    { code: 'R00.0', description: 'Tachycardia, unspecified' },
    { code: 'E11.9', description: 'Type 2 diabetes mellitus without complications' }
];

const HomePage = () => {
    const [items, setItems] = useState(initialItems); // Store items
    const [loading, setLoading] = useState(false); // Loading state
    const [searchItem, setSearchItem] = useState([]); // Search results state
    const [selectedItems, setSelectedItems] = useState([]); // Items selected by user
    const [searchResults, setSearchResults] = useState([]); // Search results selected
    const patient = useVimOSPatient();

    // Toggle the selected status of an item
    const toggleSelectItem = (item) => {
        if (selectedItems.some((selected) => selected.code === item.code)) {
            setSelectedItems(selectedItems.filter((selected) => selected.code !== item.code));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };

    // Toggle the selected status of a search result item
    const toggleSelectSearchItem = (item) => {
        if (searchResults.some((selected) => selected.code === item.code)) {
            setSearchResults(searchResults.filter((selected) => selected.code !== item.code));
        } else {
            setSearchResults([...searchResults, item]);
        }
    };

    // Fetch problem list when patient changes (memoized function)
    const getProblemList = useCallback(async () => {
        if (patient) {
            setLoading(true); // Start loading
            try {
                const problemList = await patient.getProblemList();
                console.log("Fetched Problem List:", problemList);

                // Ensure that problemList is an array before setting state
                if (Array.isArray(problemList)) {
                    setItems(problemList); // Update items with the fetched problem list
                } else {
                    console.error("Problem list is not an array:", problemList);
                }
            } catch (error) {
                console.error("Error fetching problem list:", error);
            } finally {
                setLoading(false); // End loading
            }
        }
    }, [patient]); // Only re-run if the patient changes

    // Trigger the fetch on patient change, but not on every render
    useEffect(() => {
        if (patient) {
            getProblemList();
        }
    }, [patient, getProblemList]); // Dependency on `patient` directly

    // Submit handler for form submission
    const onSubmit = (e) => {
        e.preventDefault();
        const obj = {
            suggestedResults: selectedItems,
            searchResults: searchResults,
        };
        console.log(obj); // Output selected items and search results
    };

    // Handle search query and filter out duplicates
    const handleSearch = (query) => {
        const combinedResults = [...searchResults, ...query]; // Combine old results with new query

        // Create a Map to remove duplicate items based on `code`
        const uniqueResults = Array.from(
            new Map(combinedResults.map((item) => [item.code, item])).values()
        );

        setSearchItem(uniqueResults); // Update searchItem with unique results
    };

    // If data is loading, show the Loader component
    if (loading) {
        return <Loader />;
    }

    return (
        <div className="container mx-auto p-4">
            <SearchBar setSearchItem={handleSearch} /> {/* Pass handleSearch to SearchBar */}

            <div className="text-xl mt-6">{"Suggested:"}</div>
            <div className="flex flex-wrap gap-2 mt-2">
                {items.map((item) => (
                    <div
                        key={item.code} // Use 'code' as the key (acting as unique identifier)
                        onClick={() => toggleSelectItem(item)} // Toggle item selection on click
                        className={`relative flex flex-col cursor-pointer bg-white shadow-sm border rounded-lg ${
                            selectedItems.some((selected) => selected.code === item.code)
                                ? 'border-blue-500 bg-slate-100'
                                : 'border-slate-200'
                        }`}
                    >
                        <div className="flex items-center mb-2 mt-2">
                            <h5 className="ml-3 mr-3 text-slate-800 text-xl font-semibold">
                                {item.description} {/* Use 'description' for displaying item */}
                            </h5>
                        </div>
                    </div>
                ))}
            </div>

            <div className="text-xl  mt-6">{"Search Results:"}</div>
            <div className="flex flex-wrap gap-1 mt-2">
                {searchItem?.map((item) => (
                    <div
                        key={item.code} // Use 'code' as the key (acting as unique identifier)
                        onClick={() => toggleSelectSearchItem(item)} // Toggle search item selection on click
                        className={`relative flex flex-col my-2 p-2 w-72 cursor-pointer bg-white shadow-sm border rounded-lg ${
                            searchResults.some((selected) => selected.code === item.code)
                                ? 'border-blue-500 bg-slate-100'
                                : 'border-slate-200'
                        }`}
                    >
                        <div className="flex items-center mb-4">
                            <h5 className="ml-3 text-slate-800  text-xl font-semibold truncate">
                                {item.description} {/* Use 'description' for displaying item */}
                            </h5>
                        </div>
                    </div>
                ))}
            </div>

            {searchResults.length > 0 && (
                <div className="flex justify-end mt-10">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={onSubmit} // Submit button to log selected results
                    >
                        Confirm
                    </button>
                </div>
            )}
        </div>
    );
};

export default HomePage;
