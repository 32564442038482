// src/PatientName.js

import React from "react";
import { useVimOSPatient } from "./useVimOSPatient";

export const PatientName = () => {
  const patient = useVimOSPatient();

  if (!patient) return null;
  return <div>{`${patient.demographics.firstName}`}</div>;
};
