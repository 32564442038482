import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  value: true,
}

export const sidePanelReducerSlice = createSlice({
  name: 'sidePanel',
  initialState,
  reducers: {
    setSidePanel: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSidePanel } = sidePanelReducerSlice.actions

export default sidePanelReducerSlice.reducer
