const BASE_URL = 'https://your-domain.com/api';

export const fetchItems = async () => {
    const response = await fetch(`${BASE_URL}/items`);
    return response.json();
};

export const searchItems = async (query) => {
    const response = await fetch(`${BASE_URL}/items/search?q=${query}`);
    return response.json();
};


export const saveItem = async (item) => {
    const response = await fetch(`${BASE_URL}/items`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
    });
    return response.json();
};